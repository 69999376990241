import React from "react";
import { graphql } from "gatsby";
import Gallery from "@browniebroke/gatsby-image-gallery";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "@browniebroke/gatsby-image-gallery/dist/style.css";

const Galleria = ({ data }) => {
  const images = data.images.edges.map(({ node }) => node.childImageSharp);
  return (
    <Layout>
      <SEO title="Galleria" />
      <Gallery images={images} />
    </Layout>
  );
};

export default Galleria;

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 300, maxHeight: 300, quality: 85) {
              ...GatsbyImageSharpFluid
            }

            full: fluid(maxWidth: 1024, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
